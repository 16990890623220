import React from 'react';
import {
    View,
    TextInput,
    TouchableOpacity,
    Text,
    FlatList,
    StyleSheet,
    ListRenderItem,
    KeyboardAvoidingView,
    Platform,
    Image,
    Linking,
} from 'react-native';
import _ from 'lodash';

import { getWebSocketApiUrl } from '../../lib/products/websockets';
import { getUserId } from '../../lib/common/cognito';
import { getLocaleCode } from '../../Locales';
import { Feature, updateFeatureMapVar, useFeatureMap } from '../../lib/features/buildFeatureMap';
import { useApolloClient } from '../../api/config';
import { getDevStackMode } from '../../lib/common/devStackMode';

interface AssistantStreamResponse {
    chunk: string;
}

type ProductDiscoveryConversationMessageWithProducts = ProductDiscoveryConversationMessage & {
    merchantProductOffers?: MerchantProductOffer[];
};

type ProductDiscoveryConversationMessage = {
    id: string;
    content: string;
    role: 'system' | 'user' | 'assistant';
    timestamp: number;
};

interface MerchantProductOffer {
    merchantProductOfferId: string;
    originalTitle: string;
    processedWebpageUrl: string;
    mainImageUrls: {
        defaultSize: string;
    };
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
}

function ProductDiscoveryScreen() {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        if (apolloClient) updateFeatureMapVar(apolloClient);
    }, [apolloClient]);
    const featureMap = useFeatureMap();
    if (featureMap && !!featureMap?.[Feature.productDiscoveryDesktopBrowserExtension] && Platform.OS === 'web') {
        return <ProductDiscoveryChatScreen />;
    }
    return <Text>Coming soon...</Text>;
}

function ProductDiscoveryChatScreen() {
    const [messages, setMessages] = React.useState<ProductDiscoveryConversationMessageWithProducts[]>([]);
    const [inputText, setInputText] = React.useState<string>('');
    const [conversationId, setConversationId] = React.useState<string>('');
    const [, setCurrentAssistantMessage] = React.useState<ProductDiscoveryConversationMessageWithProducts | undefined>(
        undefined
    );
    const currentAssistantMessageRef = React.useRef<ProductDiscoveryConversationMessageWithProducts | undefined>(undefined); // This state is needed for handling stream responses
    const webSocketRef = React.useRef<WebSocket | undefined>(undefined);
    const userId = getUserId();
    const isDevStack = getDevStackMode();
    const websocketUrl = getWebSocketApiUrl();
    const localeCode = getLocaleCode();
    let messageIdCounter = React.useRef(0);
    const generateUniqueId = () => {
        messageIdCounter.current += 1;
        return messageIdCounter.current.toString();
    };

    React.useEffect(() => {
        const ws = new WebSocket(websocketUrl + '?userId=' + userId);
        webSocketRef.current = ws;
        ws.onopen = () => {
            if (isDevStack) console.log('WebSocket connection opened');
            ws.send(JSON.stringify({ action: 'getProductDiscoveryConversationId', userId: userId }));
        };
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.productDiscoveryConversationId) {
                if (isDevStack)
                    console.log('Received product discovery conversation ID:', data.productDiscoveryConversationId);
                setConversationId(data.productDiscoveryConversationId);
            } else if ((data.content && data.role === 'assistant') || _.has(data, 'chunk')) {
                handleAssistantMessage(data);
            } else {
                if (isDevStack) console.warn('Unknown message format:', JSON.stringify(data));
            }
        };
        ws.onerror = (error) => {
            if (isDevStack) console.error('WebSocket error:', error);
        };
        ws.onclose = () => {
            ws.send(
                JSON.stringify({
                    action: 'closeProductDiscoveryConversation',
                    userId: userId,
                    conversationId: conversationId,
                })
            );
            if (isDevStack) console.log('WebSocket connection closed');
        };
        return () => {
            // Clean up on unmount
            ws.close();
        };
    }, []);

    /**
     * The API sends back either chunks of a message when sending back the streamed assistant response,
     * or the full message when the assistant has finished processing the user's message. The full message also
     * contains the merchant product offers, that we use to display the products within the conversation.
     */
    const handleAssistantMessage = (response: ProductDiscoveryConversationMessageWithProducts | AssistantStreamResponse) => {
        if ('chunk' in response) handleStreamAssitantMessage(response as AssistantStreamResponse);
        else if (response.content && response.role === 'assistant')
            handleFullAssistantMessage(response as ProductDiscoveryConversationMessageWithProducts);
        else console.warn('Received assistant content but no current assistant message:', response);
    };

    const handleStreamAssitantMessage = (response: AssistantStreamResponse) => {
        if (!response.chunk) return;
        if (currentAssistantMessageRef.current === undefined) {
            const assistantMessage: ProductDiscoveryConversationMessageWithProducts = {
                id: generateUniqueId(),
                timestamp: Date.now(),
                content: response.chunk,
                role: 'assistant',
            };
            setCurrentAssistantMessage(assistantMessage);
            currentAssistantMessageRef.current = assistantMessage;
            setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        } else {
            const updatedMessage = {
                ...currentAssistantMessageRef.current,
                content: (currentAssistantMessageRef.current.content || '') + response.chunk,
            };
            setCurrentAssistantMessage(updatedMessage);
            currentAssistantMessageRef.current = updatedMessage;
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === currentAssistantMessageRef.current?.id ? updatedMessage : message
                )
            );
        }
    };

    const handleFullAssistantMessage = (response: ProductDiscoveryConversationMessageWithProducts) => {
        if (currentAssistantMessageRef.current) {
            const updatedMessage = {
                ...currentAssistantMessageRef.current,
                content: response.content || currentAssistantMessageRef.current.content || '',
                merchantProductOffers: response.merchantProductOffers || [],
            };
            setCurrentAssistantMessage(undefined);
            currentAssistantMessageRef.current = undefined;
            setMessages((prevMessages) =>
                prevMessages.map((message) => (message.id === updatedMessage.id ? updatedMessage : message))
            );
        } else {
            console.warn('Received assistant content but no current assistant message:', response);
        }
    };

    const sendMessage = () => {
        if (inputText.trim().length > 0 && conversationId && webSocketRef.current) {
            const userMessage: ProductDiscoveryConversationMessageWithProducts = {
                id: generateUniqueId(),
                timestamp: Date.now(),
                content: inputText,
                role: 'user',
            };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            const payload = {
                userId: userId,
                action: 'streamProductDiscoveryMessage',
                conversationId: conversationId,
                message: inputText,
                timestamp: Date.now(),
                localeCode: localeCode,
                shouldLogAllData: true,
            };
            webSocketRef.current.send(JSON.stringify(payload));
            setInputText('');
        }
    };

    const renderItem: ListRenderItem<ProductDiscoveryConversationMessageWithProducts> = ({ item }) => {
        if (item.merchantProductOffers) {
            return (
                <View style={[styles.messageContainer, styles.assistantMessage]}>
                    <Text style={styles.messageText}>{item.content}</Text>
                    {item.merchantProductOffers.map((offer) => (
                        <View key={offer.merchantProductOfferId} style={styles.productCard}>
                            <Image
                                source={{ uri: offer.mainImageUrls.defaultSize }}
                                style={styles.productImage}
                                resizeMode="contain"
                            />
                            <Text style={styles.productTitle}>{offer.originalTitle}</Text>
                            <TouchableOpacity onPress={() => Linking.openURL(offer.processedWebpageUrl)}>
                                <Text style={styles.productLink}>View Product</Text>
                            </TouchableOpacity>
                            <Text style={styles.productPrice}>
                                {offer.priceInformation.displayPriceAmount.valueInCents / 100}{' '}
                                {offer.priceInformation.displayPriceAmount.currency}
                            </Text>
                        </View>
                    ))}
                </View>
            );
        }
        return (
            <View style={[styles.messageContainer, item.role === 'user' ? styles.userMessage : styles.assistantMessage]}>
                <Text style={styles.messageText}>{item.content}</Text>
            </View>
        );
    };

    return (
        <KeyboardAvoidingView
            style={styles.container}
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}>
            <FlatList
                data={messages}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                contentContainerStyle={styles.messagesList}
            />
            <View style={styles.inputContainer}>
                <TextInput
                    style={styles.input}
                    value={inputText}
                    onChangeText={setInputText}
                    placeholder="Type your message..."
                    onSubmitEditing={sendMessage}
                />
                <TouchableOpacity style={styles.sendButton} onPress={sendMessage}>
                    <Text style={styles.sendButtonText}>Send</Text>
                </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
    );
}

export default ProductDiscoveryScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    messagesList: {
        padding: 10,
    },
    messageContainer: {
        marginVertical: 5,
        padding: 10,
        borderRadius: 10,
        maxWidth: '80%',
    },
    userMessage: {
        backgroundColor: '#DCF8C6',
        alignSelf: 'flex-end',
    },
    assistantMessage: {
        backgroundColor: '#ECECEC',
        alignSelf: 'flex-start',
    },
    messageText: {
        fontSize: 16,
    },
    inputContainer: {
        flexDirection: 'row',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#ECECEC',
    },
    input: {
        flex: 1,
        backgroundColor: '#F5F5F5',
        borderRadius: 20,
        paddingHorizontal: 15,
        fontSize: 16,
    },
    sendButton: {
        backgroundColor: '#0084FF',
        borderRadius: 20,
        paddingHorizontal: 20,
        justifyContent: 'center',
        marginLeft: 10,
    },
    sendButtonText: {
        color: '#fff',
        fontSize: 16,
    },
    productCard: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 10,
        marginVertical: 5,
        width: '80%',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.3,
        shadowRadius: 1,
        elevation: 3,
    },
    productImage: {
        width: '100%',
        height: 150,
        borderRadius: 10,
    },
    productTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginVertical: 5,
    },
    productPrice: {
        fontSize: 14,
        color: '#888',
    },
    productLink: {
        color: '#0084FF',
        fontSize: 14,
    },
});
