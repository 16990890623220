import moment from 'moment';

import { OfferType } from '../../api/graphql/fragments/offers';
import { RewardType } from '../../api/graphql/fragments/rewards';
import { TrackingId } from '../../api/graphql/fragments/trackingConsents';
import { sessionIdVar } from '../../api/graphql/reactiveVariables';
import { webAppVersion } from '../../Version';
import { getUserId } from '../common/cognito';
import { getDeviceId } from '../common/device';
import { updateLastEventTimestampMs } from '../common/sessions';
import { getUserTrackingConsent } from '../../lib/common/trackingConsents';
import { Browser } from '../common/browser';
import { captureSentryException } from '../common/sentry';
import { AffiliateLinkLoadingMethod } from '../offers/affiliateLinkLoading';
import { getRegion, Region, RegionDetectionMethod } from '../auth/region';
import { InstallationStep } from '../mobileSafariExtension/mobileSafariExtension';
import { AdBlockerWarningNavigationButtonType } from '../../pages/public/OnlineOfferActivationPage';

const AMPLITUDE_API_KEY = 'bb40330abc74b9e9b8a4f95a086c3416';
const SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_EVENT_LOCAL_STORAGE_KEY = '@EventStore:savedAmplitudeMarketingAttributionEvent';
const SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_USER_PROPERTIES_LOCAL_STORAGE_KEY = '@EventStore:savedAmplitudeUserProperties';

interface BaseAmplitudeEvent<T = string> {
    name: T;
    properties?: undefined;
}

interface BaseAmplitudeEventWithProperties<T = string, S = { [key: string]: any }> {
    name: T;
    properties?: S;
}

// These events are logged for all users (authenticated and not authenticated)
type NavigationAmplitudeEvent =
    // Logged when the app is launched
    | BaseAmplitudeEvent<'Navigation - Launched App'>
    // Logged when we capture new attribution parameters for the user
    // Note that we run attribution check every time a new session starts
    // More info here: https://developers.amplitude.com/docs/web-attribution
    // About UTM parameters: https://en.wikipedia.org/wiki/UTM_parameters
    // About the Google Click Id (GCLID): https://support.google.com/google-ads/answer/9744275?hl=en
    | BaseAmplitudeEventWithProperties<
          '[Amplitude] Attribution Captured',
          {
              utm_source?: string;
              utm_medium?: string;
              utm_campaign?: string;
              utm_term?: string;
              utm_content?: string;
              gclid?: string;
              fbclid?: string;
              referrer?: string;
              referring_domain?: string;
          }
      >
    // Logged when users give their consent for Amplitude Analytics Tracking through the GDPR popup
    // Note that, to do so, they can click on "accept all", or click on "personalize" and select the Amplitude Analytics Tracking item
    | BaseAmplitudeEvent<'Navigation - Accepted Amplitude Analytics Tracking'>
    | BaseAmplitudeEventWithProperties<
          'Navigation - Region Detected',
          { region?: Region; city?: string; detectionMethod: RegionDetectionMethod }
      >
    | BaseAmplitudeEventWithProperties<'Navigation - Region Detection From Ip Failed', { reason: 'timeout' | 'unknown' }>;

type RegistrationAmplitudeEvent =
    // Logged when the user clicks on one of the sign-in buttons on the welcome page
    | BaseAmplitudeEvent<'Registration - Clicked Email Login'>
    | BaseAmplitudeEvent<'Registration - Clicked Facebook Login'>
    | BaseAmplitudeEvent<'Registration - Clicked Apple Login'>
    | BaseAmplitudeEvent<'Registration - Clicked Google Login'>
    // Logged when the user clicks on one of the sign-up buttons on the welcome page
    | BaseAmplitudeEvent<'Registration - Clicked Email Register'>
    | BaseAmplitudeEvent<'Registration - Clicked Apple Register'>
    | BaseAmplitudeEvent<'Registration - Clicked Facebook Register'>
    | BaseAmplitudeEvent<'Registration - Clicked Google Register'>
    // Logged when the email login process succeeds
    | BaseAmplitudeEvent<'Registration - Email Login Succeeded'>
    // Logged when the Facebook/Apple login process succeeds
    // Note: this event is logged for both sign up and sign in
    // Note: when signing up with Facebook or Apple, this event is logged when the user confirms his email
    | BaseAmplitudeEvent<'Registration - Facebook Login Succeeded'>
    | BaseAmplitudeEvent<'Registration - Apple Login Succeeded'>
    | BaseAmplitudeEvent<'Registration - Google Login Succeeded'>
    // Logged when the Facebook/Apple login process fails
    // Note: the type of error is logged as a property
    | BaseAmplitudeEventWithProperties<'Registration - Facebook Login Failed', { type: string }>
    | BaseAmplitudeEventWithProperties<'Registration - Apple Login Failed', { type: string }>
    | BaseAmplitudeEventWithProperties<'Registration - Google Login Failed', { type: string }>
    // Logged when the user clicks on the "Terms Of Service" link on the sign up page
    | BaseAmplitudeEvent<'Registration - Clicked Terms Of Service'>
    // Logged when the user clicks on the "Privacy Policy" link on the sign up page
    | BaseAmplitudeEvent<'Registration - Clicked Privacy Policy'>
    // Logged when the user ticks the checkbox for Terms Of Service and Privacy Policy on the sign up page
    | BaseAmplitudeEvent<'Registration - Ticked Checkbox For Terms Of Service and Privacy Policy'>
    // Logged when the user ticks the checkbox for the Newsletter consent on the sign up page
    | BaseAmplitudeEvent<'Registration - Ticked Checkbox For Newsletter'>
    // Logged when the user validates his/her email on the sign up page
    | BaseAmplitudeEventWithProperties<'Registration - Validated Email', { email: string }>
    // Logged when the user validates his/her password on the sign up page
    | BaseAmplitudeEvent<'Registration - Defined Password'>
    // Logged when the user validates his/her information (first name, age, gender, referral code)
    | BaseAmplitudeEvent<'Registration - Validated User Information'>
    // Logged when the onboarding process is finished
    // Note that the "onboardedAt" field in the Users table is filled at this time
    | BaseAmplitudeEvent<'Registration - Onboarding Finished'>;

type OffersAmplitudeEvent =
    // Logged when the user clicks on an offer component (everywhere in the app)
    | BaseAmplitudeEventWithProperties<'Offers - Clicked Offer', { offerId: string }>
    // Logged when the user clicks on the offer's call to action (activate, shop, etc...)
    | BaseAmplitudeEventWithProperties<
          'Offers - Clicked Offer CTA',
          { offerId: string; fromComponent: 'Home' | 'OfferSearch' | 'OffersByTag' | 'OfferDetails' | 'Other' }
      >
    // Logged when the user clicks on an offer category
    | BaseAmplitudeEventWithProperties<'Offers - Clicked Browsing Tag', { tagId: string }>
    // Logged when the user clicks on the "activate" button on an offer page
    // Note: this event is also triggered for online offers when the user clicks on the first time on the "shop through the link" button
    | BaseAmplitudeEventWithProperties<'Offers - Activated Offer', { type: OfferType; offerId: string }>
    // This event is logged at each affiliate link loading attempt
    // An affiliate link loading attempt can be triggered by different events (indicated in the `triggeringEventType` field)
    // Note that some loading attempts made in the universal offer activation page of the web app might come from other platforms (indicated in the `triggeringEventPlatform` field)
    // For instance, if the user comes from the mobile app, we will have `triggeringEventPlatform = "mobileApp"`
    // Each loading attempt uses a different affiliate link with a different "Sub ID", which is a custom ID passed to the affiliate platform to track each attempt
    // This Sub ID contains a Click ID that is generated before each attempt and that is logged in the payload of the present event
    | BaseAmplitudeEventWithProperties<
          'Offers - Clicked Affiliate Link',
          {
              offerId: string;
              merchantId: string;
              clickId: string;
              affiliateLinkUrl: string;
              triggeringEventId: string;
              triggeringEventType?: string;
              triggeringEventPlatform?: string;
              triggeringEventTimestamp?: number;
              affiliateLinkLoadingMethod: AffiliateLinkLoadingMethod;
              affiliateLinkLoadingTimestamp: number;
              attemptNumber: number;
              hasRedirectUrl: boolean;
              parametersRemovedFromRedirectUrl: string[];
              /** @deprecated replaced by triggeringEventPlatform */
              from?: string;
          }
      >
    // Logged when an attempt to append a redirection URL to the affiliate link fails
    // Note: `affiliateLink` is the original affiliate link without a redirection URL
    | BaseAmplitudeEventWithProperties<'Offers - Add Redirect URL To Affiliate Link Failed', { offerId: string }>
    /**
     * Logged when the ad blocker detection is positive.
     * The `from` field distinguishes the web app online offer activation page detection from the browser extension offer widget detection.
     */
    | BaseAmplitudeEventWithProperties<
          'Offers - Ad Blocker Detected',
          {
              from: 'onlineOfferActivationPage';
              triggeringEventPlatform: string | undefined;
              triggeringEventType: string | undefined;
          }
      >
    /**
     * Logged when the user clicks on a button of the ad-blocker warning screen.
     * If the user clicks on the "retry" button, this reloads the page and triggers a new ad-blocker detection.
     * If the user clicks on the "continue" button, this dismisses the warning and redirects to the merchant website.
     * If the user clicks on the "help" button, this redirects to our ad-blocker FAQ page.
     */
    | BaseAmplitudeEventWithProperties<
          'Offers - Clicked Ad Blocker Warning Button',
          {
              buttonType: AdBlockerWarningNavigationButtonType;
              triggeringEventPlatform: string | undefined;
              triggeringEventType: string | undefined;
          }
      >;

type PointsAmplitudeEvent =
    // Logged when users collect their points on an offer, a bonus or a referral
    | BaseAmplitudeEventWithProperties<
          'Points - Collected Points',
          {
              offerIds: string[];
              transactionIds: string[];
              affiliateTransactionIds: string[];
              bonusTitles: string[];
              referralPoints: { refereeUserId: string; referrerUserId: string }[];
          }
      >
    // Logged when users collect their points on an offer
    | BaseAmplitudeEventWithProperties<
          'Points - Collected Offer Points',
          {
              offerIds: string[];
              transactionIds: string[];
              affiliateTransactionIds: string[];
          }
      >;

type HistoryAmplitudeEvent =
    // Logged when the user opens or click on a history tab
    BaseAmplitudeEventWithProperties<'History - Clicked Tab', { tab: string }>;

type RewardsAmplitudeEvent =
    // Logged when the user redeems a reward
    | BaseAmplitudeEventWithProperties<'Rewards - Clicked Redeem', { type: RewardType; rewardId: string; points: number }>
    // Logged when the user confirms his/her redeem action
    | BaseAmplitudeEventWithProperties<'Rewards - Confirmed Redeem', { type: RewardType; rewardId: string; points: number }>
    // Logged when users confirm the activation of the auto donation of their cashback
    // Note: this event is logged from the web app on iOS (because the confirmation happens on an external browser due to Apple guidelines)
    | BaseAmplitudeEventWithProperties<
          'Rewards - Confirmed Auto Donation Settings',
          { rewardId?: string; rewardTitle?: string }
      >
    // Logged when users cancel the confirmation of the auto donation activation
    // Note: this event is logged from the web app on iOS (because the confirmation happens on an external browser due to Apple guidelines)
    | BaseAmplitudeEvent<'Rewards - Cancelled Auto Donation Settings'>;

// Logged when the user clicks on an option on the profile page
type ProfileAmplitudeEvent =
    | BaseAmplitudeEvent<'Settings - Clicked Change Email'>
    | BaseAmplitudeEventWithProperties<'Settings - Confirmed Change Email', { oldEmail: string; newEmail: string }>
    // Logged when the user clicks on the FAQ button on the profile page
    | BaseAmplitudeEvent<'Profile - Clicked FAQ'>
    // Logged when the user clicks on the "log out" button on the profile page
    | BaseAmplitudeEvent<'Settings - Clicked Logout'>
    // Logged when the user clicks on the "delete account" button on the profile page
    | BaseAmplitudeEvent<'Settings - Clicked Delete Account'>;

type BrowserExtensionAmplitudeEvent =
    // Logged when the user views the browser promotion page after the onboarding
    | BaseAmplitudeEvent<'Navigation - Viewed Browser Extension Promotion Page'>
    // Logged when the user clicks on the "download browser extension" CTA on the promotion page or on the home screen banner
    | BaseAmplitudeEventWithProperties<'Clicked Download Browser Extension', { fromComponent: 'browserExtensionBanner' }>
    // Logged when the user clicks on the "skip" button on the browser extension promotion page
    | BaseAmplitudeEvent<'Navigation - Skipped Browser Extension Promotion Page'>;

type LegalUpdateAmplitudeEvent =
    // Logged when the legal update page is displayed
    | BaseAmplitudeEventWithProperties<'Legal - Legal Updates Displayed', { legalUpdateIds: string[] }>
    // Logged when the user submits their legal update consent choice (being either true or false)
    | BaseAmplitudeEventWithProperties<
          'Legal - Submitted Legal Updates Consent Choice',
          { legalUpdateIds: string[]; consentChoice: boolean }
      >;

interface BaseMobileSafariExtensionInstallationAmplitudeEvent<T = string, S = { [key: string]: any }> {
    name: T;
    properties: S & { isRevampedOnboardingFlow: boolean };
}

/**
 * In the following events, payload fields are included only if they are relevant.
 * For example, the extension is not able to detect when the flow is done during the onboarding, such that isOnboarding is only relevant for the activation step triggered by the mobile app.
 */
type MobileSafariExtensionInstallationAmplitudeEvent =
    // Logged when the user first views any page of the mobile Safari extension installation flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Revamped Onboarding Flow AB Test Group Set',
          { shouldSeeBanner?: boolean }
      >
    // Logged when the user views the first page of the mobile Safari extension installation flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Viewed Activation Page',
          { browserKind?: Browser; isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the start activation CTA of the mobile Safari extension installation flow, which shows indications that explain how to activate the extension
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Clicked Start Activation',
          { isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the help activate CTA of the mobile Safari extension installation flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Clicked Help Activation'>
    // Logged when the user views the authorization page of the mobile Safari extension installation flow
    // This page is automatically opened when the user activates the extension: this consists in going to the "manage extensions" Safari menu and activating the Joko extension
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Viewed Authorization Page',
          { isBeforeOnboarding?: boolean; isFlowWithSystemSettings: boolean; isFlowWithBanner: boolean }
      >
    // Logged when the user clicks on the start authorization CTA of the mobile Safari extension installation flow, which shows indications that explain how to authorize the extension
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Clicked Start Authorization',
          { isBeforeOnboarding?: boolean; isFlowWithSystemSettings?: boolean }
      >
    // Logged when special instructions are displayed behind the Safari authorization popups. The popup state can be either `alwaysAllowPopup` or `allowOnAllWebsitesPopup`.
    // The first popup asks if the user wants to allow the extension permanently, for 24 hours only, or not at all.
    // The second one asks the user if they want these permissions to apply to the current website only or to all websites.
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Authorization Popup Background Instructions Displayed',
          { popupState?: 'alwaysAllowPopup' | 'allowOnAllWebsitesPopup'; browserLanguage?: string }
      >
    // Logged when the special authorization popup instructions are removed, after the popups are closed, such that the user sees the usual authorization instructions again.
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Authorization Popup Background Instructions Hidden'>
    // Logged when the user clicks on the missing authorization banner CTA, which takes the user to the regular authorization instruction page
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Clicked Missing Authorization Banner Button'>
    // Logged when the user clicks on the CTA to open the system settings, in the authorization step of the mobile Safari extension installation flow.
    // Note that this first sends the user to the mobile app, from where the user is redirected to the system settings. This is done because we are unable to open the system settings directly from Safari.
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Clicked Open System Settings'>
    // Logged when the user grants authorization to the extension; the user then automatically views the conclusion page of the mobile Safari extension installation flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Viewed Conclusion Page',
          { isOnAllWebsites?: boolean; isBeforeOnboarding?: boolean }
      >
    // Logged when the user clicks on the start test CTA of the mobile Safari extension installation flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Clicked Start Test'>
    // Logged when the user views the missing "all websites" authorization page, where they were redirected after the extension detected the missing authorization
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Viewed Missing All-Websites Authorization Page'>
    // Logged when the user clicks on the modify authorization CTA on a missing authorization page
    // The from field in the payload indicates whether it was done in the missing "all websites" authorization popup, or in the missing authorization page
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Clicked Modify Authorization',
          { from: 'missingAllWebsitesAuthorizationPopup' | 'missingAuthorizationPage' }
      >
    // Logged when the user clicks on the cross to close the popup of the missing "all websites" authorization page
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Closed Missing Authorization Popup'>
    // Logged when the user views the second missing "all websites" authorization page, after having tried and failed twice to give the correct authorization
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Viewed Missing All-Websites Authorization After Retry Page'>
    // Logged when the user views the missing authorization page, opened by the extension in a new tab in order to encourage the user to give the correct authorization
    // Note that this page can be opened days/weeks after the initial activation of the extension
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Viewed Missing Authorization Page'>
    // Logged when the user clicks on the finish later button that redirects to google.com from the missing authorization page
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Clicked Finish Later From Missing Authorization Page',
          { installationStep: InstallationStep | undefined }
      >
    // Logged when the user views the waiting page, which only shows a loading wheel
    // Two successive waiting pages are opened in a new tab by the extension in order to close a native display
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Viewed Waiting Page',
          { step?: 'firstWaitingStep' | 'secondWaitingStep' }
      >
    // Logged when the user consents to the Terms of Service and Privacy Policy in the pre-registration flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<'Mobile Safari Extension - Clicked Consent ToS And PP'>
    // Logged when an AB-test group is set during the mobile Safari extension setup flow
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - AB Test Group Set',
          { testId: string; groupId: string }
      >
    // Logged when the user clicks on the return to app CTA
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Clicked Return To App',
          { installationStep: InstallationStep | undefined }
      >
    // Logged when the user is not in Safari and views the wrong browser page
    | BaseMobileSafariExtensionInstallationAmplitudeEvent<
          'Mobile Safari Extension - Viewed Wrong Browser Page',
          { browserKind?: Browser; isOnboarding?: boolean; isBeforeOnboarding?: boolean }
      >;

type IntercomAmplitudeEvent =
    // Logged when users are logged into Intercom
    | BaseAmplitudeEvent<'Intercom - Logged In'>
    // Logged when users are logged out of Intercom
    | BaseAmplitudeEvent<'Intercom - Logged Out'>
    // Logged when the Intercom space is opened by the user when clicking on the widget
    | BaseAmplitudeEvent<'Intercom - Opened Space'>
    // Logged when the Intercom space is closed by the user when clicking on the widget
    | BaseAmplitudeEvent<'Intercom - Closed Space'>
    // Logged when user attributes are updated on Intercom
    | BaseAmplitudeEvent<'Intercom - Profile Updated'>;

interface AmplitudeEventPayload {
    device_id?: string;
    user_id?: string;
    event_type: string;
    time: number;
    user_properties?: { [key: string]: any };
    event_properties?: { [key: string]: any };
    app_version?: string;
    platform?: string;
    session_id?: number;
}

export type AmplitudeEvent =
    | NavigationAmplitudeEvent
    | RegistrationAmplitudeEvent
    | OffersAmplitudeEvent
    | PointsAmplitudeEvent
    | HistoryAmplitudeEvent
    | RewardsAmplitudeEvent
    | ProfileAmplitudeEvent
    | BrowserExtensionAmplitudeEvent
    | LegalUpdateAmplitudeEvent
    | MobileSafariExtensionInstallationAmplitudeEvent
    | IntercomAmplitudeEvent;

export async function logAmplitudeEvent(
    event: AmplitudeEvent,
    userProperties?: { [name: string]: any },
    consentChoice?: boolean
): Promise<void> {
    updateLastEventTimestampMs();
    if (consentChoice === undefined) {
        /**
         * We can only log events for which users gave us their consent.
         *
         * All Amplitude events are used for analytics today,
         * except for '[Amplitude] Attribution Captured' that is used for marketing attribution.
         */
        const trackingConsent = getUserTrackingConsent(
            event.name === '[Amplitude] Attribution Captured'
                ? TrackingId.amplitudeMarketingAttributionWebApp
                : TrackingId.amplitudeAnalyticsWebApp
        );
        consentChoice = trackingConsent?.consentChoice && trackingConsent.expiresAt > moment().unix() ? true : false;
    }
    if (consentChoice) {
        const deviceId = getDeviceId();
        const userId = getUserId();
        const timestampInMs = Date.now();
        const sessionId = sessionIdVar() || undefined;
        let payload: AmplitudeEventPayload = {
            device_id: deviceId,
            user_id: userId,
            event_type: event.name,
            time: timestampInMs,
            app_version: webAppVersion,
            platform: 'Web',
            session_id: sessionId,
        };
        if (event.properties) {
            console.log(`Amplitude Event: ${event.name} ${JSON.stringify(event.properties)}`);
            payload.event_properties = event.properties;
        } else console.log(`Amplitude Event: ${event.name}`);
        if (userProperties) payload.user_properties = { ...userProperties, region: getRegion() };
        await fetch(
            encodeURI(`https://api.amplitude.com/httpapi?api_key=${AMPLITUDE_API_KEY}&event=${JSON.stringify([payload])}`)
        ).catch((error) => {
            console.log(`Amplitude: Error ${error.message}`);
        });
    } else if (event.name === '[Amplitude] Attribution Captured')
        // If the users give their consent for marketing attribution, we'll be able to log this event - we're saving it in the meantime
        saveAmplitudeMarketingAttributionEvent(event);
}

type LocalStorageMarketingAttributionEvent = {
    pathname: string;
    event: AmplitudeEvent;
};

export function saveAmplitudeMarketingAttributionEvent(event: AmplitudeEvent): void {
    const localStorageItem: LocalStorageMarketingAttributionEvent = { pathname: window.location.pathname, event };
    localStorage.setItem(SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_EVENT_LOCAL_STORAGE_KEY, JSON.stringify(localStorageItem));
    console.log(`Amplitude Event: marketing attribution event saved for later logging`);
}

export function getSavedAmplitudeMarketingAttributionEvent(): LocalStorageMarketingAttributionEvent | undefined {
    const localStorageItem: string | null = localStorage.getItem(
        SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_EVENT_LOCAL_STORAGE_KEY
    );
    if (!localStorageItem) return undefined;
    try {
        return JSON.parse(localStorageItem);
    } catch {
        return undefined;
    }
}

export function clearSavedAmplitudeMarketingAttributionEvent() {
    localStorage.removeItem(SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_EVENT_LOCAL_STORAGE_KEY);
}

interface AmplitudeIdentityPayload {
    device_id?: string;
    user_id?: string;
    user_properties: UserPropertiesInput;
}

type UserPropertiesInput =
    | { [key: string]: string | number }
    | {
          $set?: { [key: string]: string | number };
          $setOnce?: { [key: string]: string | number };
          $add?: { [key: string]: string | number };
          $append?: { [key: string]: string | number };
          $prepend?: { [key: string]: string | number };
          $unset?: { [key: string]: string | number };
          $preInsert?: { [key: string]: string | number };
      };

// IMPORTANT: Today we only use Amplitude user properties for marketing attribution. The code below has to be adapted if we want to use it for analytics.
export async function setAmplitudeMarketingAttributionUserProperties(
    input: UserPropertiesInput,
    consentChoice?: boolean
): Promise<void> {
    if (consentChoice === undefined) {
        /**
         * We can only log user properties for which users gave us their consent.
         *
         * All Amplitude user properties are related to marketing attribution today
         */
        consentChoice = checkHasTrackingConsent();
    }
    if (consentChoice) setAmplitudeUserProperties(input, consentChoice);
    else {
        // If the users give their consent for marketing attribution, we'll be able to log these props - we're saving them in the meantime
        saveAmplitudeMarketingAttributionUserProperties(input);
    }
}

export function saveAmplitudeMarketingAttributionUserProperties(input: UserPropertiesInput): void {
    localStorage.setItem(SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_USER_PROPERTIES_LOCAL_STORAGE_KEY, JSON.stringify(input));
    console.log(`Amplitude Event: user properties saved for later logging`);
}

export function getSavedAmplitudeMarketingAttributionUserProperties(): UserPropertiesInput | undefined {
    const localStorageItem: string | null = localStorage.getItem(
        SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_USER_PROPERTIES_LOCAL_STORAGE_KEY
    );
    if (!localStorageItem) return undefined;
    try {
        return JSON.parse(localStorageItem);
    } catch {
        return undefined;
    }
}

export function clearSavedAmplitudeMarketingAttributionUserProperties() {
    localStorage.removeItem(SAVED_AMPLITUDE_MARKETING_ATTRIBUTION_USER_PROPERTIES_LOCAL_STORAGE_KEY);
}

function checkHasTrackingConsent() {
    const trackingConsent = getUserTrackingConsent(TrackingId.amplitudeMarketingAttributionWebApp);
    return Boolean(trackingConsent?.consentChoice && trackingConsent.expiresAt > moment().unix());
}

export async function setAmplitudeUserProperties(
    userProperties: UserPropertiesInput,
    consentChoice: boolean
): Promise<void> {
    if (!consentChoice) return;
    const payload: AmplitudeIdentityPayload = {
        device_id: getDeviceId(),
        user_id: getUserId(),
        user_properties: userProperties,
    };
    console.log(`Amplitude User Properties: ${JSON.stringify(userProperties)}`);
    await fetch(
        encodeURI(
            `https://api.amplitude.com/identify?api_key=${AMPLITUDE_API_KEY}&identification=${JSON.stringify([payload])}`
        )
    ).catch((error) => {
        console.log(`Amplitude: Error ${error.message}`);
    });
}

// This function can used for contexts where we know the user ID but the user is not authenticated with Cognito
export async function logAmplitudeEventWithoutAuthentication(
    event: AmplitudeEvent,
    {
        userId,
        deviceId,
        userProperties,
    }: {
        userId?: string;
        deviceId?: string;
        userProperties?: { [name: string]: any };
    }
): Promise<void> {
    updateLastEventTimestampMs();
    const deviceIdPayloadEntry = deviceId || getDeviceId();
    const timestampInMs = Date.now();
    const sessionId = sessionIdVar() || undefined;
    let payload: AmplitudeEventPayload = {
        device_id: deviceIdPayloadEntry,
        user_id: userId,
        event_type: event.name,
        time: timestampInMs,
        app_version: webAppVersion,
        platform: 'Web',
        session_id: sessionId,
    };
    if (event.properties) {
        console.log(`Amplitude Event: ${event.name} ${JSON.stringify(event.properties)}`);
        payload.event_properties = event.properties;
    } else console.log(`Amplitude Event: ${event.name}`);
    if (userProperties) payload.user_properties = { ...userProperties, region: getRegion() };
    await fetch(
        encodeURI(`https://api.amplitude.com/httpapi?api_key=${AMPLITUDE_API_KEY}&event=${JSON.stringify([payload])}`)
    ).catch((error) => {
        console.log(`Amplitude: Error ${error.message}`);
        captureSentryException(new Error(`Failed to log the ${event.name} Amplitude event: ${error.message}`));
    });
}
